/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'industries': {
      init: function() {
        // JavaScript to be fired on the about us page
        // alert('industries');
      }
    },
    'post_type_archive_products': {
      init: function(){
       /* $('#js_brand_filter').on('change', function() {
            alert('change');
            $('#hardware_products').mixItUp('filter', this.value);

        });*/
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  $(document).ready(function(){



    // To keep our code clean and modular, all custom functionality will be contained inside a single object literal called "dropdownFilter".

    var dropdownFilter = {

      // Declare any variables we will need as properties of the object

      $filters: null,
      $reset: null,
      groups: [],
      outputArray: [],
      outputString: '',

      // The "init" method will run on document ready and cache any jQuery objects we will need.

      init: function(){
        var self = this; // As a best practice, in each method we will asign "this" to the variable "self" so that it remains scope-agnostic. We will use it to refer to the parent "dropdownFilter" object so that we can share methods and properties between all parts of the object.

        self.$filters = $('#Filters');
        self.$reset = $('#Reset');
        self.$containerHardware = $('#hardware_products');
        self.$containerSoftware = $('#software_products');
        self.$containerSalesJobs = $('#sales_jobs');
        self.$containerMarketingJobs = $('#marketing_jobs');
        self.$containerFinanceJobs = $('#finance_jobs');
        self.$containerOperationsJobs = $('#operations_jobs');
        self.$containerEngineeringJobs = $('#engineering_jobs');

        self.$filters.find('fieldset').each(function(){
          self.groups.push({
            $dropdown: $(this).find('select'),
            active: ''
          });

        });

        self.bindHandlers();
      },

      // The "bindHandlers" method will listen for whenever a select is changed.
      bindHandlers: function(){
        var self = this;

        // Handle select change
        self.$filters.on('change', 'select', function(e){
          e.preventDefault();
          self.parseFilters();
        });
        // Handle reset click
        self.$reset.on('click', function(e){
          e.preventDefault();
          self.$filters.find('select').val('');
          self.parseFilters();
        });
      },

      // The parseFilters method pulls the value of each active select option
      parseFilters: function(){
        var self = this;

        // loop through each filter group and grap the value from each one.
        for(var i = 0, group; group = self.groups[i]; i++){
          group.active = group.$dropdown.val();
        }

        self.concatenate();
      },

      // The "concatenate" method will crawl through each group, concatenating filters as desired:
      concatenate: function(){
        // console.log('real');
        var self = this;

        self.outputString = ''; // Reset output string
        for(var i = 0, group; group = self.groups[i]; i++){
          self.outputString += group.active;
        }

        // If the output string is empty, show all rather than none:
        if (!self.outputString.length) {
          self.outputString = 'all';
        }

        //console.log(self.outputString);
        // ^ we can check the console here to take a look at the filter string that is produced
        // Send the output string to MixItUp via the 'filter' method:
        if(self.$containerHardware.mixItUp('isLoaded')){
          self.$containerHardware.mixItUp('filter', self.outputString);
        }
        if(self.$containerSoftware.mixItUp('isLoaded')){
          self.$containerSoftware.mixItUp('filter', self.outputString);
        }
        if(self.$containerSalesJobs.mixItUp('isLoaded')){
          self.$containerSalesJobs.mixItUp('filter', self.outputString);
        }
        if(self.$containerMarketingJobs.mixItUp('isLoaded')){
          self.$containerMarketingJobs.mixItUp('filter', self.outputString);
        }
        if(self.$containerFinanceJobs.mixItUp('isLoaded')){
          self.$containerFinanceJobs.mixItUp('filter', self.outputString);
        }
        if(self.$containerEngineeringJobs.mixItUp('isLoaded')){
          self.$containerEngineeringJobs.mixItUp('filter', self.outputString);
        }
        if(self.$containerOperationsJobs.mixItUp('isLoaded')){
          self.$containerOperationsJobs.mixItUp('filter', self.outputString);
        }
      }
    };

    // On document ready, initialise our code.

    $(function(){

      // Initialize dropdownFilter code

      dropdownFilter.init();

      var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
      };


      // Instantiate MixItUp

      var noItemsFoundMessage = $(".noItemsFoundMessage");

      // Get the url paramenters value from the URL
      // use this to build the industry filter
      var industryFilter = getUrlParameter('init-filter');
      var brandFilter = getUrlParameter('brand-filter');

      if(industryFilter) {
        initFilter = '.' + industryFilter;
        $('#Filters').find('select.industry').val(''+initFilter+'');
      } else if(brandFilter) {
        initFilter = '.' + brandFilter;
        $('#Filters').find('select.brand').val(''+initFilter+'');
      } else {
        initFilter = 'all';
        $('#Filters').find('select.industry').val('');
        $('#Filters').find('select.brand').val('');
      }

      // if(brandFilter) {
      //   initFilter = '.' + brandFilter;
      //   $('#Filters').find('select.brand').val(''+initFilter+'');
      // } else {
      //   initFilter = 'all';
      //   $('#Filters').find('select.brand').val('');
      // }

      $('#hardware_products').mixItUp({
        controls: {
          enable: false // we won't be needing these
        },
        load: {
          filter: initFilter
        },
        callbacks: {
          onMixFail: function(){
            $(".noItemsFoundHardware").css('display', 'block');
          },
          onMixEnd: function(state){
            // ## 3 - hasFailed true? show alert
            if(state.hasFailed){
              $(".noItemsFoundHardware").css('display', 'block');
            }
            // ## 3 - hasFailed false? hide alert
            else{
              $(".noItemsFoundHardware").css('display', 'none');
            }

          }
        }
      });

      $('#software_products').mixItUp({
        controls: {
          enable: false // we won't be needing these
        },
        load: {
          filter: initFilter
        },
        callbacks: {


          onMixFail: function(){
            $(".noItemsFoundSoftware").css('display', 'block');
          },

          onMixEnd: function(state){
            // ## 3 - hasFailed true? show alert
            if(state.hasFailed){
              $(".noItemsFoundSoftware").css('display', 'block');
            }
            // ## 3 - hasFailed false? hide alert
            else{
              $(".noItemsFoundSoftware").css('display', 'none');
            }
          }
        }
      });

    // end of function
    });

    //Slick slider initialize
    $('.slider').slick({
      arrows:true,
      dots: true,
      infinite:true,
      speed:500,
      autoplay:false,
      autoplaySpeed: 3000,
      slidesToShow:1,
      slidesToScroll:1,
      adaptiveHeight: true
      //asNavFor: '.slider-nav'
    });
    //On click of slider-nav childern,
    //Slick slider navigate to the respective index.
   /* $('.slider-nav > div').click(function() {
        $('.slider').slick('slickGoTo',$(this).index());
    });*/
    $('.slider-nav').slick({
      arrows:false,
      dots: false,
      infinite:true,
      speed:500,
      autoplay:false,
      autoplaySpeed: 3000,
      slidesToShow:4,
      slidesToScroll:1,
      adaptiveHeight: false,
      asNavFor: '.slider'
    });



  });

})(jQuery); // Fully reference jQuery after this point.



/**
 * Get Cookie by name
 *
 * @param cname
 * @returns {string}
 */
function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

/**
 * Set Cookie
 *
 * @param cname
 * @param cvalue
 * @param exdays
 */
function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
